import React from "react"
import Helmet from "react-helmet"

function BlogPostSEO({ description,  title,   siteUrl, dateCreated, datePublished, dateModified, authorName, imageUrl  }) {
  return (
    <Helmet
         
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:image`,
          content: imageUrl
        },
        {
          property: `og:url`,
          content: siteUrl
        },
        {
          property: `og:locale`,
          content: `de_DE`
        },
        {
          property: `og:sitename`,
          content: `Proceed GmbH`
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: authorName,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat()}
    >

          <script type="application/ld+json">{`
            {
              "@context": "https://schema.org/",
              "@type": "BlogPosting",
              "headline": "${title}",
              "url": "${siteUrl}",
              "image": "${imageUrl}",
              "dateCreated": "${dateCreated}",
              "datePublished": "${datePublished}",
              "dateModified": "${dateModified}",
              "description": "${description}",
              "inLanguage": "de-DE",
              "keywords": "Agilität",
              "author": {
                "@type": "Person",
                "name": "${authorName}"
                },
              "publisher": {
                "@type": "Organization",
                "name": "Proceed GmbH & Co KG",
                "url": "https://proceed.gmbh",
                "logo":{
                  "@type": "ImageObject",
                  "url": "public/icons/icon-48x48.png",
                  "width":"48",
                  "height":"48"
                  }
                },
                "@graph":[{
                  "@type": "WebPage",
                  "name": "${title}",
                  "url": "https://www.proceed.gmbh/blog",
                  "description": "${description}",
                  "inLanguage": "de-DE",
                  "isPartOf": {
                    "@type": "WebSite",
                    "@id": "https://www.proceed.gmbh/#website",
                    "url": "https://www.proceed.gmbh/",
                    "name": "Proceed GmbH & Co KG",
                    "inLanguage": "de-DE"
                    }
                  },{
                  "@type": "BreadcrumbList",
                  "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                      "@type": "WebPage",
                      "@id": "https://www.proceed.gmbh",
                      "url": "https://www.proceed.gmbh",
                      "name": "Das Experten-Team für Kulturwandel im Unternehmen"
                    }
                  },{
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                      "@type": "WebPage",
                      "@id": "https://www.proceed.gmbh/wissenswertes",
                      "url": "https://www.proceed.gmbh/wissenswertes",
                      "name": "Akademie - Bringen Sie Ihr Unternehmen auf den Weg"
                      }
                    },
                    {
                      "@type": "ListItem",
                      "position": 3,
                      "item": {
                        "@type": "WebPage",
                        "@id": "${siteUrl}",
                        "url": "${siteUrl}",
                        "name": "${title}"
                      }
                  }]
                }]
            }
            
        `}</script>
      
      
      </Helmet>
  )
}



export default BlogPostSEO
