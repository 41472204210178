import React from 'react';
import { gql, useQuery } from "@apollo/client";
import { TeamIconMedium } from './team/teamicon';
import { dark, orange, white } from '../styles/globalstyles';
import { Link } from 'gatsby';
import {css} from '@emotion/react';


const REFERENCES = gql`
query blog($id: ID!) {
    blog(where: {id: $id}) {
      id
      autor{
          id
          vorname
          nachname
        }
    }
  }
`
const BlogAutorlist = ({id}) => {
    const {loading, error, data} = useQuery(REFERENCES, {variables: {id}})
    if (loading) return null
    if (error) return null
    if (data) return <TeamIconMedium id={data.blog.autor.id} titel="Autor" />


}
export default BlogAutorlist;

export const BlogAutor = ({autor}) => {
  const titel = "Autor"
      return(
        <div  css={{width: "auto"}}>

          <Link to={"/team/" + autor.vorname.toLowerCase() + "-" + autor.nachname.toLowerCase()}>
          <div className="buttonShadow" css={css`display: inline-block; flex-direction: row; justify-content: flex-start; align-items: center;margin-top: 1.8em; margin-bottom: 1.3em; padding: 0.3em 2.5em 0.3em 0.5em; border-radius: 2.45em; background: ${white}; } `}> 
              <div css={css`overflow: hidden; width: 4.4em; height: 4.4em; border-radius: 50%; border: 1px solid ${orange}; float: left;`}>
                <img src={autor.portrait.url} alt={autor.vorname + " " + autor.nachname} /> 
              </div>
              <div css={css`padding-left: 0.6em;line-height: 0.84em; float: left;`}>
                  <h5 css={css`font-weight: 600 !important; color: ${orange} !important; margin-bottom: 0.4em !important;`}>{titel}</h5> 
                    <h5 css={css`color: ${dark} !important; line-height: 1.4em; margin-bottom: 0 !important;`}>{autor.vorname + " " + autor.nachname}</h5> 
              </div> 
          </div>
          </Link>
        </div>

          )
  }